var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$style.brands},[_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.search},[_c('Autocomplete',{attrs:{"search":_vm.search,"placeholder":"Поиск","valueNames":['name']},on:{"querySearch":_vm.querySearch,"selectItem":_vm.handleSelectBrand,"handleFindItems":_vm.handleFindBrands,"input":function($event){_vm.search = $event}}}),_c('el-button',{class:_vm.$style.button,staticStyle:{"margin-left":"0","margin-bottom":"0","border-radius":"0"},attrs:{"type":"primary"},on:{"click":_vm.getList}},[_vm._v(" Показать ")])],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.CREATE)}}},[_vm._v(" Создать бренд ")])],1),_c('el-table',{attrs:{"data":_vm.brands,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"orderField","label":"Приоритет","width":"300"}}),_c('el-table-column',{attrs:{"prop":"images","label":"Фото"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{attrs:{"src":scope.row.logo
              ? _vm.$configData.s3_link + scope.row.logo.original
              : '/img/gallery/default.jpg',"width":"80","height":"80","alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Название"}}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","edit-link":_vm.getRoute({
              route: _vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.BRAND,
              params: { id: scope.row.id },
            }),"viewLink":_vm.getRoute({
              route: _vm.$options.ADDWINE_ROUTES.BRANDS.BRAND,
              params: { id: scope.row.slug },
              site: _vm.$options.PROJECTS.ADDWINE,
            })},on:{"delete":function($event){return _vm.remove(scope.row)}}})]}}])})],1),_c('el-pagination',{class:_vm.$style.pagination,attrs:{"layout":"prev, pager, next","page-size":_vm.limit,"total":_vm.total,"current-page":_vm.page,"background":""},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }