<template>
  <section :class="$style.brands">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectBrand"
          @handleFindItems="handleFindBrands"
          @input="search = $event"
        />
        <el-button
          :class="$style.button"
          style="margin-left: 0; margin-bottom: 0; border-radius: 0"
          type="primary"
          @click="getList"
        >
          Показать
        </el-button>
      </div>
      <el-button
        type="primary"
        @click="
          $router.push($options.ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.CREATE)
        "
      >
        Создать бренд
      </el-button>
    </div>
    <el-table :data="brands" stripe>
      <el-table-column
        prop="orderField"
        label="Приоритет"
        width="300"
      ></el-table-column>
      <el-table-column prop="images" label="Фото">
        <template slot-scope="scope">
          <img
            :src="
              scope.row.logo
                ? $configData.s3_link + scope.row.logo.original
                : '/img/gallery/default.jpg'
            "
            width="80"
            height="80"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.BRAND,
                params: { id: scope.row.id },
              })
            "
            :viewLink="
              getRoute({
                route: $options.ADDWINE_ROUTES.BRANDS.BRAND,
                params: { id: scope.row.slug },
                site: $options.PROJECTS.ADDWINE,
              })
            "
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'

import {
  ADDWINE_ROUTES,
  getRoute,
  ADDWINE_ADMIN_ROUTES,
} from '@/constants/routing'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'
import { PROJECTS } from '@/constants/projects'

export default {
  mixins: [ addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: {
    ActionButtons,
    Autocomplete,
  },
  ADDWINE_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  PROJECTS,
  data() {
    return {
      brands: [],
      limit: 10,
      page: 1,
      total: 10,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.search = this.$route.query.search || ''
      this.limit = this.$route.query.limit || 10
      this.orderBy = this.$route.query.orderBy || 'order_field'
      this.orderDir = this.$route.query.orderDir || 'desc'
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'order_field',
        orderDir: 'desc',
      }
      if (this.search) {
        query.search = this.search
      }
      const { value, error } =
        await delivery.ProductsCore.BrandsActions.getList(query)
        this.updatedQueryParams(query)
      loading.close()

      if (error) return

      this.brands = value.data
      this.total = value.meta.count
    },
    async remove(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name} ?`)
      if (isConfirm) {
        const res = await delivery.ProductsCore.BrandsActions.delete(data.id)
        if (!res.error) {
          this.getList()
        }
      }
    },
    handleSelectBrand(selectedBrand) {
      this.$router.push(
        this.getRoute({
          route: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.BRAND,
          params: { id: selectedBrand.id },
        }),
      )
    },
    async handleFindBrands() {
      await this.getList()
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.BrandsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
    getRoute({ route, site, params }) {
      return getRoute({ route, site, params })
    },
  },
}
</script>

<style lang="scss" module>
.brands {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
